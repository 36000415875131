.search-overlay,
.overlay--job-form,
.overlay--support,
.overlay--kontakt-form {
    div.success {
        display: none;
    }    

    @include transform(translateY(-100%));
    @include transition(all .3s ease);
    background: rgba($color-default, .97);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
        @include transform(translateY(0));
        @include transition(all .3s ease);
    }
    
    #search-query {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: none;
        border: 0;
        border-bottom: 2px solid rgba(#FFF,.65);
        color: white;
        font-size: 1.2rem;
        @include media-query(screen-lg) {
            width: 35%;
        }
        line-height: 2.4rem;
        text-align: center;
        &:focus {
            outline: none;
            border-bottom-color: white;
        }
        &::placeholder {
            color: rgba(#FFF,.65);
        }
    }
    .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 4rem;
        font-weight: 100;
        color: #FFF;
        cursor: pointer;
    }
    .overlay_container {

        h2 {
            font-weight: 500;
        }
        width: 25%;
        @include media-query(screen-sm-max) {
            width: 75%;
        }
        * {
            color: #FFF;
        }
        label {
            display: block;
            text-align: center;
        }
        input.text {
            display: block;
            width: 100%;
            text-align: center;
            color: #000;
            background: none;
            -webkit-appearance: none;
            border: 0;
            border-bottom: 3px solid #fff;
            color: #FFF;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: rgba(#fff,.35);
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: rgba(#fff,.35);
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: rgba(#fff,.35);
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: rgba(#fff,.35);
            }
            &:focus {
                outline: none;
            }
        }
        .submit_container {
            margin-top: 1rem;
            text-align: center;
            > input {
                background: #000;
                color: #fff;
                font-weight: bold;
                padding: .5rem 2rem;
                border: 0;
                @include transition(all .3s ease);
                &:hover,
                &:focus {
                    background: darken($color-default, 10%);
                }
            }
        }
    }
}

.overlay--kontakt-form {
    @include transform(translateY(-150%));
    align-items: unset;
    .close {
        text-shadow: 0px 0px 6px rgba(0,0,0,.85);
    }
    .overlay_container {
        label, input, textarea, select, option {
            color: #000;
        }
        width: 100%;
        overflow: auto;
        padding: 2rem;
        @include media-query(screen-md) {
            width: 45%;
        }
    }
}