// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *
//
// MIXINS/GENERAL
// --------------------------------------------------
// VARIABLES
// USED FROM _variables.scss
// --------------------------------------------------
// font-size
@mixin font-size($font-size, $line-height: auto) {
    @warn "The font-size mixin is deprecated. Please use font-size and line-height instead. Das font-size mixin ist veraltet. Bitte verwende stattdessen font-size und line-height."
}

// margin-auto
@mixin margin-auto {
    margin-left: auto;
    margin-right: auto;
}

// basic styles for lists used as navs
@mixin navigation() {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

@mixin padding-default($column__gutter--half: $grid__gutter--half) {
    padding-left: $column__gutter--half;
    padding-right: $column__gutter--half;
}

// make-row
@mixin make-row($column__gutter--half: $grid__gutter--half) {
    @extend %clearfix;
    margin-left: $column__gutter--half * -1;
    margin-right: $column__gutter--half * -1;
}

// column-floating
@mixin column-floating($column__gutter--half: $grid__gutter--half) {
    float: left;
    padding-left: $column__gutter--half;
    padding-right: $column__gutter--half;
}

// make-column mixin
@mixin make-col($columns) {
    @include column-floating($grid__gutter--half);
    width: percentage($columns/$grid__columns);
}

// make-width mixin
// [1] fallback for browser that can't calculate
@mixin make-width($width, $align: auto) {
    $width-percentage: $width / $grid__columns * $wrapper-max;
    max-width: $width-percentage; //[1]
    max-width: calc(#{$width-percentage} - #{$grid__gutter});
    @if $align==auto or center {
        margin-left: auto;
        margin-right: auto;
    }
    @if $align==left {
        margin-left: 0;
        margin-right: auto;
    }
    @if $align==right {
        margin-right: 0;
        margin-left: auto;
    }
}

// make-offset
@mixin make-offset($width, $direction: left) {
    @if $direction==left or none {
        margin-left: percentage($width / $grid__columns);
    }
}

// screen reader only
@mixin invisible {
    width: 0;
    height: 0;
    left: -1000px;
    top: -1000px;
    position: absolute;
    overflow: hidden;
    display: inline;
}

// Functions 
// function map-deep-get()
// function to get a value from a nested map, see _type.scss for usage
//
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

// function headings()
// function to generate headlines h1 – h6
//
// usage:
// #{headings()} { … } will result in
// h1, h2, … h6 { … }
// ---
// #{headings(2,4) { … } will result in
// h2,h3,h4 { … }
// ---
@function headings($first:1, $last:6) {
    @if $first==$last {
        @return 'h#{$first}';
    }
    @else {
        @return 'h#{$first},'+headings($first+1, $last);
    }
}