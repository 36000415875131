@import "../base/layouts";

/******************************
    SONSTIGES
******************************/

body {
    &.no--page-image {
        #container {
            margin-top: 150px;
        }
    }
    &.news-article {
        p, h1, h2, h3, h4, h5, h6 {
            text-align: left;
        }
    }
    overflow-x: hidden;
}


/******************************
    HEADER
******************************/

$header--height: 80px;
$preheader--height: 40px;

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header--height;
    z-index: 3;
    @include transform(translateY(40px));
    @include transition(all .3s ease);
    @include drop-shadow(0px, 1px, 1px, 0px, .1);
    background: $color-body;
    .logo--holder,
    .navigation--holder {
        position: relative;
        height: $header--height;
    }
    .logo {
        @include transform(translateY(-50%));
        top: 50%;
        position: absolute;
        width: 100%;
        img {
            width: 200px;
            margin: 0;
            -webkit-backface-visibility: visible;
            @include media-query(screen-md-max) {
                width: 170px;
            }
            @include media-query(screen-sm-max) {
                width: 150px;
            }
        }
    }
    &.is-stuck {
        @include media-query(screen-sm-max) {
            @include transform(translateY(0));
            @include transition(all .3s ease);
        }
    }
}
body.header-stuck {
    .preheader {
        @include media-query(screen-sm-max) {
            @include transform(translateY(-100%));
            @include transition(all .3s ease);
        }
    }
}
.preheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $preheader--height;
    @include transition(all .3s ease);
    @include transform(translateY(0));
    z-index: 21;
    .inside {
        max-width: 66.625em;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }
    background: $color-default;
    text-align: right;
    .preheader--content {
        position: relative;
    }
    .language-selector {
        height: 40px;
        line-height: 40px;
        font-size: .9rem;
        display: inline-block;
        top: 50%;
        @include transform(translateY(-50%));
        position: absolute;
        right: calc(#{$grid--gutter__half} + 9rem);
        a, a:visited, a:active {
            margin-left: .5rem;
            display: inline-block;
            span {
                &.active {
                    color: $color-body;
                }
            }
        }
    }
    span, ul {
        display: inline-block;
        color: rgba(255,255,255,.5);
    }
    ul {
        margin: 0;
        height: 40px;
        line-height: 40px;
        display: inline-block;
        li {
            display: inline;
            a, a:visited, a:active {
                color: $color-body;
                font-size: 1.8rem;
                margin: 0 .5rem;
                &:hover {
                    color: #eee;
                }
            }
        }
    } 
    .social-icons {
        ul {
            
            display: inline-flex
        }
        li {
            a {
                display: inline-flex
            }
            img {
                width: 30px;
                position: relative;
                top: 3px;
            }
        }
    }
    @include media-query(screen-xs-max) {
        .social-icons {
            @include opacity(0);
            display: none;
        }
        .language-selector {
            right: $grid--gutter__half;
            line-height: 80px;
        }
    }
}

/******************************
    NAVIGATION
******************************/
.mod_navigation {
    position: absolute;
    width: 100%;
    top: 50%;
    right: $grid--gutter__half;
    @include transform(translateY(-50%));
    text-align: right;
    ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            a, a:visited, a:active {
                color: $color-base;
                font-size: 1.1rem;
                padding: 0 1rem;
                display: block;
                &:hover {
                    color: $color-default;
                }
            }
            &:first-child {
                a, a:visited, a:active {
                    padding-left: 0;
                }
            }
            &:last-child {
                a, a:visited, a:active {
                    padding-right: 0;
                }
            }
            &.kontakt {
                display: none;
            }
        }
    }
}

/******************************
    CONTENT
******************************/
#container {
    margin-top: calc(#{$header--height} + #{$preheader--height});
}
.mm-opened {
    #container {
        margin-top: 0;
    }
}

.mod_article {
    width: 100%;
    max-width: 100%;
    > .inside {
        max-width: 66.625em;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        position: relative;
    }
    &.articleBlue {
        background: $color-default;
        color: $color-body;
    }
    &.articleBlack {
        background: $color-base;
        color: $color-body;
    }
    &.articleGrey {
        background: $color-light-grey;
        color: $color-base;
    }
    &.article--full {
        > .inside {
            max-width: 100%;
            padding: 0;
        }
    }
}
.mod_article:not([class*="py"]) {
    padding: 0;
}
.mod_article[class*="py"] {
    padding-left: 0;
    padding-right: 0;
}

/******************************
    FOOTER
******************************/
footer {
    background: $color-base;
    padding: 3rem 0 4rem;
    color: $color-body;
    a, a:visited, a:active {
        color: $color-body;
        &:hover {
            color: $color-grey;
        }
    }
    h3, p {
        text-align: left;
    }
    h3 {
        @include media-query(screen-sm-max) {
            font-weight: 700;
        }
    }
    .navigation--holder,
    .contact--holder {
        border-left: 1px solid $color-body;
        padding-left: 3rem;
        position: relative;
        @include media-query(screen-sm-max) {
            margin-bottom: 1rem;
        }
    }
    .contact--holder {
        @include media-query(screen-sm-max) {
            margin-bottom: 0;
        }
    }
    .hilfsnav {
        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: left;
        }
        ul {
            li {
                a, a:visited, a:active {
                    padding: .2rem 0;
                    display: block;
                }
            }
        }
    }
    .logodf {
        position: absolute;
        bottom: 0;
        width: 250px;
        @include media-query(screen-sm-max) {
            @include transform(translateY(150%));
        }
    }
    .social-icons {
        display: none;
        @include media-query(screen-xs-max) {
            display: block;
            ul, li {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            li, a {
                display: inline-block;
                font-size: 2.2rem !important;
                padding-right: 1rem;
            }
            ul {
                margin-bottom: 2rem;
            }
        }
    }
}