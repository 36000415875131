@import "../base/mixins_general";

/*************************************
    MIXINS
*************************************/
// PUT YOUR OWN MIXINS HERE
// hyphenate
@mixin hyphenate() {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

// vertical align
@mixin vertical-align($position: 50%, $correct: -50%) {
    position: relative;
    top: $position;
    -webkit-transform: translateY($correct);
    -ms-transform: translateY($correct);
    transform: translateY($correct);
}

// un-vertical-align
@mixin un-vertical-align($position: auto, $correct: 0) {
    position: relative;
    top: $position;
    -webkit-transform: translateY($correct);
    -ms-transform: translateY($correct);
    transform: translateY($correct);
}

@mixin un-align($position: auto, $correct: 0) {
    position: static;
    top: $position;
    left: $position;
    right: $position;
    bottom: $position;
    -webkit-transform: translateY($correct) translateX($correct) !important;
    -ms-transform: translateY($correct) translateX($correct) !important;
    transform: translateY($correct) translateX($correct) !important;
}
// horizontal-align
@mixin horizontal-align($position: 50%, $correct: -50%) {
    position: relative;
    left: $position;
    -webkit-transform: translateX($correct);
    -ms-transform: translateX($correct);
    transform: translateX($correct);
}

@mixin full-align($position: 50%, $correct: -50%) {
    position: relative;
    left: $position;
    top: $position;
    -webkit-transform: translateY($correct) translateX($correct) !important;
    -ms-transform: translateY($correct) translateX($correct) !important;
    transform: translateY($correct) translateX($correct) !important;
}

@mixin background-opacity($color: $color-default, $opacity: 0.5) {
    background: $color !important;
    background: rgba($color, $opacity) !important;
}
@mixin text-opacity($color: $color-default, $opacity: 0.5) {
    color: $color;
    color: rgba($color, $opacity);
}
@mixin border-opacity($color: $color-default, $opacity: 0.5) {
    border-color: $color;
    border-color: rgba($color, $opacity);
}

// text-shadow
@mixin text-shadow($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
    text-shadow: $string;
}

// box-shadow
@mixin box-shadow($string) {
    box-shadow: $string;
    -webkit-box-shadow: $string;
    -moz-box-shadow: $string;
}

// drop-shadow
@mixin drop-shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
    -webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

// inner-shadow
@mixin inner-shadow($x, $y, $blur, $spread, $alpha) {
    -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    -moz-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
    box-shadow: inset $y $blur $spread rgba(0, 0, 0, $alpha);
}

// box-sizing
@mixin box-sizing($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    box-sizing: $type;
}

// border-radius
@mixin border-radius($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

@mixin border-radiuses($topright: $radius, $bottomright: $radius, $bottomleft: $radius, $topleft: $radius) {
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    border-top-left-radius: $topleft;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

// opacity
@mixin opacity($opacity: 0.5) {
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
    opacity: $opacity;
}

// vertical-gradient
@mixin vertical-gradient($color-start: $color-highlight, $color-end: $color-body) {
    background-color: $color-start;
    background: -webkit-gradient(linear, to bottom, from($color-start), to($color-end));
    background: -webkit-linear-gradient(to bottom, $color-start, $color-end);
    background: -moz-linear-gradient(to bottom, $color-start, $color-end);
    background: -ms-linear-gradient(to bottom, $color-start, $color-end);
    background: linear-gradient(to bottom, $color-start, $color-end);
}

// horizontal-gradient
@mixin horizontal-gradient($color-start: $color-highlight, $color-end: $color-body) {
    background-color: $color-start;
    background-image: -webkit-gradient(linear, to right, from($color-start), to($color-end));
    background-image: -webkit-linear-gradient(to right, $color-start, $color-end);
    background-image: -moz-linear-gradient(to right, $color-start, $color-end);
    background-image: -ms-linear-gradient(to right, $color-start, $color-end);
    background-image: linear-gradient(to right, $color-start, $color-end);
}

// css transitions
@mixin animation($name, $duration: 300ms, $delay: 0, $ease: ease) {
    -webkit-animation: $name $duration $delay $ease;
    -moz-animation: $name $duration $delay $ease;
    -ms-animation: $name $duration $delay $ease;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}

@mixin transform($string) {
    -webkit-transform: $string;
    -moz-transform: $string;
    -ms-transform: $string;
    transform: $string;
}

@mixin scale ($factor) {
    -webkit-transform: scale($factor);
    -moz-transform: scale($factor);
    -ms-transform: scale($factor);
    transform: scale($factor);
}
@mixin scaleX($factor) {
    -webkit-transform: scaleX($factor);
    -moz-transform: scaleX($factor);
    -ms-transform: scaleX($factor);
    transform: scaleX($factor);
}

@mixin rotate ($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin skew ($deg, $deg2) {
    -webkit-transform: skew($deg, $deg2);
    -moz-transform: skew($deg, $deg2);
    -ms-transform: skew($deg, $deg2);
    transform: skew($deg, $deg2);
}

@mixin translate ($x, $y:0) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translate3d ($x, $y: 0, $z: 0) {
    -webkit-transform: translate3d($x, $y, $z);
    -moz-transform: translate3d($x, $y, $z);
    -ms-transform: translate3d($x, $y, $z);
    transform: translate3d($x, $y, $z);
}

@mixin perspective ($value: 1000) {
    -webkit-perspective: $value;
    -moz-perspective: $value;
    -ms-perspective: $value;
    perspective: $value;
}

@mixin transform-origin ($x:center, $y:center) {
    -webkit-transform-origin: $x $y;
    -moz-transform-origin: $x $y;
    -ms-transform-origin: $x $y;
    transform-origin: $x $y;
}

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
   @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}


@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}

//The Mixin
//==========

@mixin long-shadow($type, $color, $length, $fadeout: true, $skew: false, $direction: right){
    $shadow: '';
    @if $skew == false or $type == text{
      @if $direction == right {
        @for $i from 0 to $length - 1 {
          $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' + $color + ',';
        }
      }
      @if $direction == left {
        @for $i from 0 to $length - 1 {
          $shadow: $shadow + $i * -1 + 'px ' + $i + 'px 0 ' + $color + ',';
         }
        }
       }
          
     @if $fadeout == true{
      @for $i from 1 to $length - 1 {
        @if $type == text or $skew == false{
          @if $direction == right{
            $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' +       rgba($color, 1 - $i / $length) + ',';
          }
          @if $direction == left{
            $shadow: $shadow + $i * -1 + 'px ' + $i + 'px 0 ' +       rgba($color, 1 - $i / $length) + ',';
          }
        }
        @if ($type == box) and $skew == true{
          @if $direction == right {
            $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' + $i * .2 + 'px ' + rgba($color, 1 - $i / $length) + ',';
          }
          @if $direction == left {
            $shadow: $shadow + $i * -1 + 'px ' + $i + 'px 0 ' + $i * .2 + 'px ' + rgba($color, 1 - $i / $length) + ',';
          }
        }
    }
    $shadow: $shadow + $length + 'px ' + $length + 'px 0 ' + rgba($color, 0);
   }
   @if $fadeout == false{
     @if $skew == true and ( $type == box ){
       @for $i from 0 to $length - 1 {
              $shadow: $shadow + $i + 'px ' + $i + 'px 0 ' + $i * .1 + 'px ' + $color + ',';
        }
     }
      $shadow: $shadow + $length + 'px ' + $length + 'px 0 ' + rgba(0,0,0,0);
   }
   $shadow: unquote($shadow);
    @if $type == 'box' {box-shadow: $shadow;}
    @if $type == 'text' {text-shadow: $shadow;}
  }
  