
.search--result {
    border-bottom: 1px solid #ccc;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    h4 {
        font-weight: bold;
    }
}
.search-results-input {
    margin-bottom: 4rem;
    width: 100%;
    padding: .25rem .5rem;
    border: 1px solid #ccc;
}
.search-results-label {
    color: #999;
    font-weight: bold;
}
#search-results {
    .infos {
        p {
            font-size: .95rem;
            line-height: 1.1rem;
        }
    }
}

.search--header {
    display: none;
    @media screen and (max-width: 1080px) {
        display: block;
        position: absolute;
        top: .3rem;
        left: 1rem;
        font-size: 1rem;
        color: #FFF;
    }
    @media screen and (max-width: 540px) {
        top: .4rem;
    }
}
.search--header {
    cursor: pointer;
    i.la {
        margin-right: 8px;
    }
    .search--label {
        font-size: .9rem;
        color: white;
    }
    @media screen and (max-width: 320px) {
        .search--label {
            display: none;
        }
    }
}