// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/GRID
// --------------------------------------------------

// VARIABLES

// USED FROM _responsive.scss
// $breakpoints

// --------------------------------------------------

// grid options
$grid__columns:       12 !default;
$grid__gutter:        1.875rem !default; // 30px
$grid__gutter--half:  ($grid__gutter / 2) !default;

// general row-class
.row {
  @include make-row;
}

// column default floating
%column-floating {
  float: left;
  padding-left:  $grid__gutter--half;
  padding-right: $grid__gutter--half;
  width: 100%;
}

// [1] length($breakpoints) - go through length of available viewports (xs, sm, md, lg, xl == 5)
// [2] $current - actual viewport (e.g. "xs")
// [3] $i - counter, returns the actual grid-column
// [4] $grid__columns - go through available columns (e.g. 1 --> 12)

// [5] override clear- class for the next viewport by default
// this means, you can add a clear-xs class to an element, but the clear will only affect the current viewport.  
@for $size from 1 through length($breakpoints) { // [1]
  $x:   nth($breakpoints, $size); // [2]
  $current:   nth($x, 1); // [2]
  // @warn "Item: #{length($breakpoints)}, Current: #{$current}";

  // column creation
  @for $i from 1 through $grid__columns { // [3][4]
    .col-#{$current}-#{$i} { // [2][3]
      @extend %column-floating;
    }
  }
  
  // width creation for every col- class
  @include media-query(screen-#{$current}) { // [2]

  	@for $i from 1 through $grid__columns { // [3][4]
  		.col-#{$current}-#{$i} { // [2][3]
  			width: percentage($i / $grid__columns);
  		}
  	}
  }
  
  // offset creation
  @include media-query(screen-#{$current}) { // [2]
  
  	@for $i from 0 through ($grid__columns - 1) { // [3]
  		.offset-#{$current}-#{$i} { // [2][3]
  			margin-left: percentage($i / $grid__columns);
  		}
  	}
  }
  
  //pull-left & -right creation
  @include media-query(screen-#{$current}) { // [2]
    .pull-right-#{$current} { // [2]
      float: right;
    }
    
    .pull-left-#{$current} { // [2]
      float: left;
    }
  }
  
  //clear creation  
  @include media-query(screen-#{$current}) { // [2]
    .clear-#{$current} { // [2]
      clear: both;
    }
  }
  
  // [5]
  @if($size < length($breakpoints)) {
    $size: $size + 1;
    $x:   nth($breakpoints, $size);
    $next:      nth($x, 1);
    
    // clear-#{$next}
    @include media-query(screen-#{$next}) {
      .clear-#{$current} {
        clear: none;
      }
    }
  }
}
