.mod_sidebar {
    position: absolute;
    top: 50%;
    right: 0;
    @include transform(translateY(-50%));
    z-index: 2;
    display: none;

    &.active {
        .sidebar--item {
            @include transform(translateX(100%));
            @include transition(all .3s ease);
        }
    }

    .sidebar--item {
        width: 80px;
        height: 80px;
        text-align: center;
        background: $color-default;
        position: relative;
        margin-bottom: .5rem;
        @include transform(translateX(0%));
        @include transition(all .3s ease);
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        > a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 15%;
            cursor: pointer;
            > i,
            > span {
                color: $color-body;
                display: block;
                text-transform: uppercase;
            }
            > span {
                font-size: .8rem;
            }
            > i {
                font-size: 2rem;
            }
        }
        &:hover {
            background: lighten($color-default, 10%);
        }
    }
    .sidebar--box {
        -webkit-backface-visibility: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 500px;
        background: #FFF;
        @include drop-shadow();
        padding: 1rem 2rem;
        z-index: 11;
        @include transform(translateX(100%));
        @include transition(all .3s ease);
        &.kontakt {
            top: calc(80px + .5rem);
        }
        &.jobs {
            top: calc((80px * 2) + (.5rem * 2));
        }
        .close {
            top: 1rem;
            right: 1rem;
            position: absolute;
            cursor: pointer;
            width: 40px;
            height: 40px;
            line-height: 35px;
            font-size: 32px;
            text-align: center;
            &:hover {
                color: $color-default;
            }
            &:after {
                content: "\f00d";
                font-family: "Line Awesome Free";
                font-weight: 900;
            }
        }
        .text_container {
            * {
                text-align: left;
            }
            h3 {
                font-weight: bold;
            }
        }
        .info_container {
            .info {
                text-align: left;
                margin-bottom: .5rem;
                i {
                    font-size: 1.4rem;
                    margin-right: 1rem;
                }
                span {
                    position: relative;
                    top: -2px;
                    &.phone {
                        left: 2px;
                    }
                }
            }
        }
        .button_container {
            .button {
                width: calc(50% - 1.875rem);
                display: block;
                float: left;
                padding: .5rem;
                left: .9375rem;
                position: relative;
                margin-right: .9375rem;
                background: $color-default;
                color: $color-body;
                border: 0;
                text-align: center;
                min-width: unset;
                &:hover {
                    background: lighten($color-default, 10%);
                }
                &:before {
                    content: none;
                }
            }
        }
        &.active {
            @include transform(translateX(0%));
            @include transition(all .3s ease);
        }
    }
    @media screen and (max-width: 1080px) {
        display: none;
    }
}

.mod_pageimage {
    .mod_sidebar {
        display: block;
        @media screen and (max-width: 1080px) {
            display: none;
        }
    }
}
.no--page-image,
.news-article {
    .mod_sidebar {
        display: block;
        top: 15rem;
        @media screen and (max-width: 1080px) {
            display: none;
        }
    }
    .mod_pageimage {
        .mod_sidebar {
            display: none;
        }
    }
}