  .pricing-plan {
    border-bottom: 1px solid #e1f1ff;
    display: flex;
    flex-direction: column;
    transition: all .3s ease;
    border: 1px solid #ccc;
    background: #FFF;
    &:hover {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    }
    &.is-featured {
      opacity: 1;
      box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02);
      transform: scale(1.05);
      border: 1px solid $color-default;
      &:hover {
        box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.21), 0px 8px 20px 7px rgba(0, 0, 0, 0.1);
      }
      overflow: hidden;
      &:before {
        width: 135px;
        height: 30px;
        transform: rotate(45deg);
        content: "Favorit";
        position: absolute;
        background: white;
        right: -40px;
        top: 10px;
        text-align: center;
        text-transform: uppercase;
        color: #1E398F;
        font-size: .75rem;
        font-weight: bold;
      }
    }
  }
  
  .pricing-plan:last-child {
    border-bottom: none;
  }
  
  
  .pricing-img {
    margin-bottom: 25px;
    max-width: 100%;
  }
  
  .pricing-header {
    background-color: $color-default;
    color: #FFF;
    font-weight: 600;
    letter-spacing: 2px;
    display: block;
    padding: 25px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  
  .pricing-features {
    color: $color-default;
    font-weight: 600;
    padding: 0;
    font-size: 14px;
    letter-spacing: 1px;
    list-style: none;
    margin: 0;
  }
  
  .pricing-features-item {
    border-top: 1px solid #e1f1ff;
    line-height: 1.5;
    padding: 12px 0;
    span {
        display: block;
    }
    .label {
        font-weight: bold;
    }
  }
  
  .pricing-features-item:last-child {
    border-bottom: 1px solid #e1f1ff;
  }
  
  .pricing-price {
    color: #016FF9;
    display: block;
    font-size: 32px;
    font-weight: 700;
  }
  
  .pricing-button {
    border: 1px solid $color-default;;
    color: $color-default;
    display: inline-block;
    margin: 0;
    padding: 15px 35px;
    text-align: center;
    text-decoration: none;
    transition: all 150ms ease-in-out;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .pricing-button:hover,
  .pricing-button:focus {
    background: lighten($color-default, 10%);
    color: $color-base;
    text-decoration: none;
  }
  
  .pricing-button.is-featured {
    background-color: $color-default;
    color: #fff;
  }
  
  .pricing-button.is-featured:hover,
  .pricing-button.is-featured:active {
    background: lighten($color-default, 10%);
  }