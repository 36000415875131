@import "../base/media";

/************************
    HERO
************************/
.mod_pageimage {
    overflow: visible;
    position: relative;
    .image_container {
        margin: 0;
        img {
            width: 100%!important;
        }
    }
}

img {
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
}

figure {
     &.image-left {
         float: left;
         margin-right: $grid--gutter__half;
         margin-bottom: $grid--gutter__half;
         max-width: 360px;
     }
     &.image-big {
         max-height: 500px;
         width: 100%;
     }
     &.image-right {
         float: right;
         margin-left: $grid--gutter__half;
         margin-bottom: $grid--gutter__half;
         max-width: 360px;
     }
}

.ce_newsletter {
    color: $color-grey;
}

.ce_halfbox {
    margin-bottom: 24px;
    .inside {
        background: #000;
    }
    figure {
        margin: 0;
    }
    .image_container {
        @include media-query(screen-sm-max) {
            max-height: 480px;
            overflow: hidden;
        }
        .background_image {
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
        img {
            display: none;
        }
        @include media-query(screen-sm-max) {
            img {
                display: block;
            }
        }
    }
    .text_container {
        color: #FFF;
        position: relative;
        .text {
            padding: 8rem 4rem;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            padding-right: calc(4rem + #{$grid--gutter__half} + #{$grid--gutter});
        }
    }
    &.fixed {
        &.color {
            &.col_default {
                .inside {
                    background: $color-default;
                }
            }
        }
    }
    @include media-query(screen-sm-max) {
        .text_container {
            .text {
                padding: 2rem;
                padding-right: 2rem;
            }
        }
    }
}