@import "../base/responsive";

@include media-query(screen-lg-max) {

}

@include media-query(screen-md-max) {

}

@include media-query(screen-sm-max) {

}

@include media-query(screen-xs-max) {
    h1 {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}