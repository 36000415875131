@import "../base/type";

$font: 'Open Sans', Arial, Verdana, sans-serif;

body {
    font: 1rem/1.8rem $font, sans-serif;
    @include media-query(screen-xs-max) {
        font: .9rem/1.6rem $font, sans-serif;
    }
    &.agb {
        h1,h2,h3,h4,h5,h6 {
            text-align: left;
        }
    }
}

.text.columnized {
    @include media-query(screen-md) {
        column-count: 2;
        column-gap: 1rem;
        p {
            text-align: left;
        }
    }
}

p {
    margin-bottom: .5rem;
    margin-top: .2rem;
    text-align: center;
    font-size: 1.25rem;
    line-height: 2.0rem;
    font-weight: 100;
    @include media-query(screen-sm-max) {
        font-size: 1.15rem;
    }
    @include media-query(screen-xs-max) {
        font-size: 1rem;
    }
}
p, ul {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
        margin-top: 1.5rem;
    }
}
a {
    color: $color-default;
}

h1,h2,h3,h4,h5,h6 {
    font: 1rem/2rem $font, sans-serif;
    margin-bottom: 1rem;
    margin-top: 0;
    text-align: center;
}

h1 {
    font: 2.4rem/2.8rem $font, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: $color-default;
    @include media-query(screen-sm-max) {
        font-size: 1.6rem;
        line-height: 1.35;
    }
    
}
h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 500;
    @include media-query(screen-sm-max) {
        font-size: 1.4rem;
        line-height: 1.35;
    }
}
h3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
    @include media-query(screen-xs-max) {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}
h4 {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
    @include media-query(screen-xs-max) {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}

.textCenter {
    text-align: center;
    p,
    h1,h2,h3,h4,h5,h6 {
        text-align: center;
    }
}
.alignLeft {
    text-align: left;
    p, ul, h1, h2, h3, h4, h5, h6, li, strong, span {
        text-align: left;
    }

    ul {
        list-style-position: outside;
        margin-left: 0;
        li {
            margin-left: -14px;
        }
    }
}


ul {
    text-align: center;
    list-style-position: inside;
    &.halfbox_ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-size: 1.25rem;
        line-height: 2.0rem;
        font-weight: 100;
    }
    @include media-query(screen-sm-max) {
        list-style-position: outside;
        li {
            font-size: 1rem;
        }
    }
}
.mod_article.newslist {
    ul {
        text-align: left;
        margin: 0;
        padding: 0;
        list-style-position: inside;
    }
}

ul {
    margin: 0;
    padding: 0;
    ul {
        padding-left: 2rem;
    }
}

.button {
    background: $color-base;
    color: $color-body;
    padding: 1rem 2rem;
    margin: 1rem auto;
    display: inline-block;
    @include transition(all .3s ease-in);
    text-decoration: none;
    @include media-query(screen-md) {
        min-width: 350px;
    }
    border-radius: 10px;
    text-align: center;
    font-size: 1rem;

    &.white {
        background: $color-body;
        color: $color-default;
        @include transition(all .3s ease-in);
        &:hover {
            background: $color-default;
            color: $color-body;
            @include transition(all .3s ease-out);
        }
        &:active {
            background: lighten($color-default, 10%);
        }
    }
    &.blue {
        background: $color-default;
        color: $color-body;
        @include transition(all .3s ease-in);
        &:hover {
            background: lighten($color-default, 10%);
            @include transition(all .3s ease-out);
        }
        &:active {
            background: lighten($color-base, 10%);
        }
    }
    &:hover {
        background: $color-default;
        @include transition(all .3s ease-out);
        text-decoration: none;
    }
    &:active {
        background: lighten($color-default, 5%);
        text-decoration: none;
    }
}

a, a:visited, a:active, a:hover {
    text-decoration: none;
}

.back {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(0,0,0,.25);
    text-align: right;
    a,a:visited,a:active {
        background: $color-base;
        color: $color-body;
        padding: .5rem 1rem;
        @include transition(all .3s ease-in);
        &:hover {
            background: $color-default;
        }
        &:before {
            content: "<";
            margin-right: 5px;
        }
    }
}

.uppercase {
    text-transform: uppercase;
}

ol {
    counter-reset: li;
    list-style: none;
    margin: 0 $grid--gutter__half;
    padding: 0 $grid--gutter__half;
    margin-bottom: 2rem;
    li {
        position: relative;
        margin: 1rem 0;
        padding: 1rem 0;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: -2rem;
        margin-right: -2rem;
        background: rgba(0,0,0,0.025);
        &:before {
            counter-increment: li;
            content: counter(li);
            position: absolute;
            left: -1rem;
            font-size: 1.6rem;
            @include transform(translateY(-50%));
            width: 35px;
            height: 35px;
            background: $color-body;;
            text-align: center;
            line-height: 35px;
            border-radius: 50%;
            top: 50%;
            @include inner-shadow(0px, 0px, 1px, 1px, 0.05);
        }
    }
}

footer {
    ol li, ul li, p, a {
        font-size: 1.1rem;
    }
}